import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { GKModal, GKSelect, GKTextField } from "components";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import {
  COMPANY_CAP,
  COMPANY_INSTTYPE,
  COMPANY_STATUS,
  INSTRUMENT_SUB_TYPE,
  MUTUAL_FUND_SUB_TYPE,
} from "utils/constants";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  refetch: any;
}

function CreateCompanyModal(props: Props) {
  const { isModalOpen, setIsModalOpen, refetch } = props;

  const { mutate, isLoading } = useMutation({
    mutationKey: ["CREATE_COMPANY"],
    mutationFn: (data) => axiosInstance.post(`/admin-instrument/create/`, data),
    onSuccess: (res) => {
      toast.success(res.data.message);
      refetch();
      setIsModalOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      cap: "",
      finCode: "",
      house: "",
      industry: "",
      instType: "",
      kiteCode: "",
      name: "",
      sector: "",
      shortName: "",
      status: "",
      bseCode: "",
      nseCode: "",
      isinCode: "",
      series: "",
      instSubType: "",
      amfiCode: "",
      mfSubType: "",
    },
    onSubmit: (values: any) => mutate(values),
  });

  return (
    <GKModal
      open={isModalOpen}
      setOpen={setIsModalOpen}
      modalTitle={"Add New Company"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button type="submit" form="create-company-form" variant="outlined">
            {isLoading ? <CircularProgress size={14} /> : "Submit"}
          </Button>
        </Grid>
      }
    >
      <form onSubmit={formik.handleSubmit} id="create-company-form">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="name"
                onChange={formik?.handleChange}
                value={formik.values.name}
                name="name"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="ShortName"
                onChange={formik?.handleChange}
                value={formik.values.shortName}
                name="shortName"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="FinCode"
                onChange={formik?.handleChange}
                value={formik.values.finCode}
                name="finCode"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="InstType"
                onChange={formik?.handleChange}
                value={formik.values.instType}
                name="instType"
              >
                {COMPANY_INSTTYPE.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="Cap"
                onChange={formik?.handleChange}
                value={formik.values.cap}
                name="cap"
              >
                {COMPANY_CAP.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="BSE"
                onChange={formik?.handleChange}
                value={formik.values.bseCode}
                name="bseCode"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="NSE"
                onChange={formik?.handleChange}
                value={formik.values.nseCode}
                name="nseCode"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="Mutual Funds Sub Type"
                onChange={formik?.handleChange}
                value={formik.values.mfSubType}
                name="mfSubType"
              >
                {MUTUAL_FUND_SUB_TYPE.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="AMFI Code"
                onChange={formik?.handleChange}
                value={formik.values.amfiCode}
                name="amfiCode"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Sector"
                onChange={formik?.handleChange}
                value={formik.values.sector}
                name="sector"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Industry"
                onChange={formik?.handleChange}
                value={formik.values.industry}
                name="industry"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="House"
                onChange={formik?.handleChange}
                value={formik.values.house}
                name="house"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="KiteCode"
                onChange={formik?.handleChange}
                value={formik.values.kiteCode}
                name="kiteCode"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="Status"
                onChange={formik?.handleChange}
                value={formik.values.status}
                name="status"
              >
                {COMPANY_STATUS.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="ISIN"
                onChange={formik?.handleChange}
                value={formik.values.isinCode}
                name="isinCode"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Series"
                onChange={formik?.handleChange}
                value={formik.values.series}
                name="series"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="Instrument Sub Type"
                onChange={formik?.handleChange}
                value={formik.values.instSubType}
                name="instSubType"
              >
                {INSTRUMENT_SUB_TYPE.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
}

export default CreateCompanyModal;
