import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Popper,
  Switch,
  TableContainer,
  Typography,
  styled,
} from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { BiFilter } from "react-icons/bi";
import { MdClose, MdExpandLess, MdExpandMore } from "react-icons/md";
import { theme } from "utils/Theme";
import CommonFilterModal from "./CommonFilterModal";
import GKTextField from "./GKTextField";

interface Props {
  headerComponent?: any;
  headerGridStyle?: any;
  showSearch?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densityButton?: boolean;
  exportButton?: boolean;
  searchPlaceholder?: string;
  showFilters?: boolean;
  ref?: any;
  CustomPagination?: any;
  additionalComponent?: any;
  setFilterColumn?: any;
  filterColumn?: any;
  exportCsv?: any;
  maxHeight?: any;
  hideHeaderGrid?: any;
  showHeaderGrid?: any;
  zeroHolding?: any;
  showNonZero?: any;
  setShowNonZero?: any;
  handleSortChange?: any;
  paginationData?: any;
  setPaginationData?: any;
  rawSearch?: any;
  setRawSearch?: any;
  setSearch?: any;
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "calc(100vh - 260px)",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill:
      theme.palette.mode === "light" ? theme.palette.light?.main : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill:
      theme.palette.mode === "light" ? "#f5f5f5" : theme.palette.light?.main,
  },
}));

const StyledGridToolbarColumnsButton = styled(GridToolbarColumnsButton)(
  ({ theme }) => ({
    background: `${theme.palette.primary.main}44`,
    padding: "8px  16px",
    minWidth: 150,
  })
);
const StyledGridToolbarFilterButton = styled(GridToolbarFilterButton)(
  ({ theme }) => ({
    background: `${theme.palette.primary.main}44`,
    padding: "8px  16px",
    minWidth: 150,
  })
);
const StyledButton = styled(Button)(({ theme }) => ({
  background: `${theme.palette.primary.main}44`,
  padding: "8px  16px",
  minWidth: 150,
}));

const GKNewDataGrid = (props: DataGridProps & Props) => {
  const {
    rows,
    headerComponent,
    showSearch = true,
    showFilters = true,
    loading,
    ref,
    columnsButton = true,
    filterButton = true,
    CustomPagination,
    additionalComponent,
    columns,
    setFilterColumn,
    filterColumn,
    exportCsv,
    maxHeight,
    showHeaderGrid = true,
    zeroHolding,
    showNonZero,
    setShowNonZero,
    handleSortChange,
    paginationData,
    setPaginationData,
    onRowClick,
    rawSearch,
    setRawSearch,
    setSearch,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const [filterModal, setFilterModal] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSearch(rawSearch);
    }, 400);

    return () => {
      clearTimeout(timerId);
    };
  }, [rawSearch]);

  const CustomToolbar = (prProps: any) => {
    const { value, onInputChange } = prProps;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //   setAnchorEl(anchorEl ? null : event.currentTarget)
    // }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    return (
      showHeaderGrid && (
        <Grid
          container
          justifyContent={"space-between"}
          p={1}
          alignItems={"center"}
          ref={ref}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 10,
              width: "100%",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              {showSearch && (
                <GKTextField
                  style={{ width: 200 }}
                  placeholder={"Search..."}
                  value={value}
                  onChange={onInputChange}
                  type="search"
                  autoFocus={isFocused}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
              )}
              {/* <IconButton onClick={handleClick}>
                <HiFilter size={22} />
              </IconButton> */}
            </span>
            {headerComponent && headerComponent}
          </div>

          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{
              zIndex: 4,
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: "0px",
                bgcolor: "background.paper",
                boxShadow: `-10px 10px 21px ${theme.palette.primary.main}44`,
                border: `solid 1px ${theme.palette.primary.main}33`,
                width: 350,
                position: "relative",
              }}
            >
              <IconButton
                style={{
                  position: "absolute",
                  top: "-15px",
                  right: "-14px",
                }}
                color="error"
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                <MdClose />
              </IconButton>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={600} color={theme.palette.grey[700]}>
                    Columns & Other Filters
                  </Typography>
                  <Divider
                    style={{
                      margin: "4px 0",
                    }}
                  />
                  {showFilters && (
                    <GridToolbarContainer>
                      {columnsButton && <StyledGridToolbarColumnsButton />}
                      {filterButton ? (
                        <StyledGridToolbarFilterButton />
                      ) : (
                        <StyledButton onClick={() => setFilterModal(true)}>
                          <BiFilter
                            size={20}
                            fill={theme.palette.primary.main}
                          />
                          &nbsp; Filter
                        </StyledButton>
                      )}
                    </GridToolbarContainer>
                  )}
                  <br />
                  {zeroHolding && (
                    <FormControlLabel
                      style={{
                        marginLeft: 2,
                      }}
                      control={
                        <Switch
                          onChange={(event) => {
                            setShowNonZero(event.target.checked);
                          }}
                          checked={showNonZero}
                        />
                      }
                      labelPlacement="end"
                      label={
                        <b
                          style={{
                            fontSize: 12,
                          }}
                        >
                          Show zero holding
                        </b>
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight={600} color={theme.palette.grey[700]}>
                    Export Data
                  </Typography>
                  <Divider
                    style={{
                      margin: "4px 0",
                    }}
                  />
                  {exportCsv && exportCsv()}
                </Grid>
              </Grid>
            </Box>
          </Popper>
        </Grid>
      )
    );
  };

  const CustomLoader = () => {
    return (
      <StyledGridOverlay>
        <div className="spinner">
          <div> </div>
          <div> </div>
          <div> </div>
          <div> </div>
          <div> </div>
          <div> </div>
        </div>
        <Typography
          marginTop={5}
          fontWeight={600}
          fontSize={20}
          width={"60%"}
          textAlign={"center"}
          color={theme.palette.primary.main}
        >
          Hold on tight we are fetching data.
        </Typography>
      </StyledGridOverlay>
    );
  };

  const Pagination = () => {
    return <div> </div>;
  };

  const EmptyData = () => {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box>No Data Found.</Box>
        {additionalComponent}
      </StyledGridOverlay>
    );
  };

  // const filteredRows = rows.filter((row) => {
  //   return Object.values(row).some((value) => {
  //     return String(value).toLowerCase().includes(searchText.toLowerCase());
  //   });
  // });

  return (
    <TableContainer>
      {loading ? (
        <DataGrid
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: EmptyData,
            loadingOverlay: CustomLoader,
          }}
          {...props}
          slotProps={{
            toolbar: {
              value: rawSearch,
              onInputChange: (event: any) => setRawSearch(event.target.value),
            },
          }}
          loading
          rows={[]}
        />
      ) : (
        <DataGrid
          onSortModelChange={handleSortChange}
          {...props}
          slotProps={{
            toolbar: {
              value: rawSearch,
              onInputChange: (event: any) => {
                setRawSearch(event.target.value);
                if (setPaginationData) {
                  setPaginationData({
                    ...paginationData,
                    page: 0,
                    skip: 0,
                  });
                }
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: EmptyData,
            loadingOverlay: CustomLoader,
            detailPanelExpandIcon: () => <MdExpandMore size={20} />,
            detailPanelCollapseIcon: () => <MdExpandLess size={20} />,
            footer: CustomPagination || Pagination,
          }}
          // rows={(searchText ? filteredRows : rows) || []}
          rows={rows || []}
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              maxHeight: maxHeight || "calc(100vh - 256px)",
            },
            "&.MuiDataGrid-root .MuiTablePagination-root": {
              padding: "0 8px",
            },
            "& .MuiDataGrid-row": {
              "&:hover": {
                cursor: onRowClick ? "pointer" : "",
              },
            },
          }}
        />
      )}

      {filterModal && (
        <CommonFilterModal
          isModalOpen={filterModal}
          setIsModalOpen={setFilterModal}
          columns={columns}
          setFilterColumn={setFilterColumn}
          filterColumn={filterColumn}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
        />
      )}
    </TableContainer>
  );
};

export default GKNewDataGrid;
