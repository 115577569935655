import { Button, FormControl, Grid, TextField } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GKModal, GKSearchSelect, GKTextField } from "components";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  refetch: any;
  groupData: any;
}

const GroupModal = (props: Props) => {
  const { open, setOpen, refetch, groupData } = props;

  const groupId = groupData?.id;

  const [userData, setUserData] = useState([]);

  const { isLoading } = useQuery({
    queryKey: ["GET_ALL_USERS"],
    queryFn: () => axiosInstance.get("/super_admin/advisors/"),
    onSuccess: (data) => {
      setUserData(
        data.data.data.map((item: any) => ({
          name: `${item.name} - ${item.phone}` || "-",
          value: item.id,
        }))
      );
    },
  });

  const { mutate: handleCreateGroup } = useMutation({
    mutationKey: ["CREATE_TEMPLATE"],
    mutationFn: (data: any) => axiosInstance.post("/advisor_group/", data),
    onSuccess: (response) => {
      refetch();
      setOpen(false);
      toast.success(response?.data?.message || "Create Group Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleUpdateGroup } = useMutation({
    mutationKey: ["UPDATE_GROUP"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/advisor_group/${groupId}/`, data),
    onSuccess: (response) => {
      refetch();
      setOpen(false);
      toast.success(response?.data?.message || "Update Group Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleDeleteGroup } = useMutation({
    mutationKey: ["DELETE_GROUP"],
    mutationFn: () => axiosInstance.delete(`/advisor_group/${groupId}/`),
    onSuccess: (response) => {
      refetch();
      setOpen(false);
      toast.success(response?.data?.message || "Delete Group Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      title: groupId ? groupData?.title : "",
      users: groupId ? groupData.users.map((item: any) => item.id) : [],
    },
    onSubmit: async (values) => {
      if (groupId) {
        handleUpdateGroup(values);
      } else {
        handleCreateGroup(values);
      }
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={groupId ? "Update Group" : "Create Group"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button type="submit" form="create-group-form" variant="outlined">
            {groupId ? "Update" : "Save"}
          </Button>
        </Grid>
      }
      titleComponent={
        groupId && (
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleDeleteGroup()}
          >
            Delete Group
          </Button>
        )
      }
    >
      <form id="create-group-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Group Name"
                name="title"
                value={formik.values.title}
                onChange={formik?.handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <GKSearchSelect
              multiple
              disableClearable={false}
              disableCloseOnSelect
              limitTags={5}
              inputLabel="User"
              loading={isLoading}
              options={userData || []}
              onChange={(e, val) => {
                const userId = val.map((item: any) => item.value);
                formik.setFieldValue("users", userId);
              }}
              value={
                userData?.filter((option: any) =>
                  formik.values?.users?.includes(option.value)
                ) || []
              }
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    formik.values?.users?.length <= 0 && "Select User"
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default GroupModal;
