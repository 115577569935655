/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconButton, Switch, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { ConfirmDeleteModal, GKClientDataGrid } from "components";
import dayjs from "dayjs";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import accessBoardService from "services/accessBoard";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

function AdminAccessBoard() {
  const {
    state: { currentUser },
  } = useAppContext();

  const queryClient = useQueryClient();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const {
    data: accessList,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.ACCESS_LIST],
    queryFn: () => axiosInstance.get("/user/all_user/"),
  });

  const { mutate: deleteClient } = useMutation({
    mutationKey: ["DELETE_CLIENT"],
    mutationFn: (clientId) =>
      axiosInstance.delete(`/super_admin/${clientId}/delete-user/`),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Update Broker Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("User Name", "name", 0, 1),
    },

    { ...extraColumnsField("User Phone", "phone", 0, 1) },

    {
      ...extraColumnsField("User Email", "email", 220, 1),
      renderCell: (item) => (
        <Typography textTransform="lowercase">{item?.row?.email}</Typography>
      ),
    },
    {
      ...extraColumnsField("Is Active", "isActive", 100, 1),
      renderCell: (item) => (item?.row?.isActive ? "Yes" : "No"),
    },
    {
      ...extraColumnsField("Is Verified", "isVerified", 100, 1),
      renderCell: (item) => (item?.row?.isVerified ? "Yes" : "No"),
    },
    {
      ...extraColumnsField("User Role", "userRole", 100, 1),
    },
    {
      ...extraColumnsField("Created On", "dateJoined", 0, 1),
      valueFormatter: (data) => dayjs(data?.value).format("DD-MM-YYYY"),
    },
    {
      ...extraColumnsField("Grant Access", "grantAccess", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (item) => (
        <Switch
          checked={item?.row?.grantAccess}
          onChange={async (value) => {
            const response = await accessBoardService.grantAccess({
              userPhoneNumber: item?.row?.phone,
              hasAccess: value.target.checked,
            });
            if (response.status !== 200) {
              toast.error("Failed to grant access");
            }
            toast.success(
              `Access ${value ? "granted" : "revoked"} successfully`
            );
            queryClient.invalidateQueries([QueryKeys.ACCESS_LIST]);
          }}
        />
      ),
    },
    currentUser.phone === "7016333333" && {
      ...extraColumnsField("Delete User", "deleteClient", 0, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (item) => (
        <IconButton
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedClientId(item?.row?.id);
            setConfirmationModal(true);
          }}
        >
          <MdDelete />
        </IconButton>
      ),
    },
  ];

  return (
    <DashboardLayout title="Client Board">
      <GKClientDataGrid
        columns={columns}
        rows={accessList?.data || []}
        loading={isRefetching || isLoading}
      />
      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => deleteClient(selectedClientId)}
          title="Delete User"
          isDelete
        />
      )}
    </DashboardLayout>
  );
}

export default AdminAccessBoard;
