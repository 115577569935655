import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { GridColDef, GridExpandMoreIcon } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { GKClientDataGrid } from "components";
import dayjs from "dayjs";
import { useState } from "react";
import { CiSettings } from "react-icons/ci";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, moneyCommaSeparator } from "utils/helpers";
import { theme } from "utils/Theme";
import WalletTopupModal from "./WalletTopupModal";

const WalletTopup = () => {
  const { id } = useParams();

  const [openTopupModal, setOpenTopupModal] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["GET_WALLET_BALANCE"],
    queryFn: () => axiosInstance.get(`/super_admin/${id}/get_wallet_balance/`),
  });

  const {
    data: walletHistory,
    isLoading,
    refetch: walletHistoryRefetch,
  } = useQuery({
    queryKey: ["GET_WALLET_HISTORY"],
    queryFn: () =>
      axiosInstance.get(`/super_admin/${id}/get_wallet_balance_history/`),
  });

  const brokerColumns: GridColDef[] = [
    {
      ...extraColumnsField("Topup Date", "CreatedAt", 150, 1),
      valueGetter: (params) =>
        dayjs(params.row.createdAt).format("DD-MM-YYYY HH:mm"),
    },
    {
      ...extraColumnsField("Amount", "amount", 150, 1),
    },
  ];

  return (
    <>
      <Accordion
        disableGutters
        style={{
          border: `solid 1px ${theme.palette.dark?.main}44`,
          borderRadius: "8px",
          boxShadow: "none",
          background: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          sx={{
            "& .MuiAccordionSummary-content": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={600}
            textTransform={"none"}
            color={theme.palette.primary.main}
          >
            Wallet Topup
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={600}
            color={theme.palette.dark?.main}
          >
            Wallet Balance
            <Typography fontSize={14} fontWeight={500}>
              {moneyCommaSeparator(data?.data?.data?.amount)}
            </Typography>
          </Typography>

          <IconButton onClick={() => setOpenTopupModal(true)}>
            <CiSettings />
          </IconButton>
        </AccordionDetails>
        <GKClientDataGrid
          loading={isLoading}
          columns={brokerColumns}
          rows={walletHistory?.data?.data}
          showFiltersButton={false}
        />
      </Accordion>

      {openTopupModal && (
        <WalletTopupModal
          open={openTopupModal}
          setOpen={setOpenTopupModal}
          refetch={refetch}
          walletHistoryRefetch={walletHistoryRefetch}
        />
      )}
    </>
  );
};

export default WalletTopup;
