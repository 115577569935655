import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  GKModal,
  GKSearchSelect,
  GKSelect,
  GKTextArea,
  GKTextField,
} from "components";
import { FieldArray, Form, Formik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { RiDeleteBinLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { CORPORATE_ACTION_TYPE } from "utils/constants";
import { QueryKeys } from "utils/queryKey";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  refetch?: any;
}

function AddCorporateActionModal(props: Props) {
  const { isModalOpen, setIsModalOpen, refetch }: any = props;
  const { id } = useParams();

  const [perentInstrument, setPerentInstrument] = useState([]);

  const { isFetching } = useQuery({
    queryKey: [QueryKeys.CORPORATE_ACTION_COMPANY_LIST],
    queryFn: () => axiosInstance.get("/fetch_all_short_active/"),
    onSuccess: (data) => {
      setPerentInstrument(
        data.data.map((item: any) => ({
          name: item.companyName,
          value: item.gcode,
          isin: item.isin,
        }))
      );
    },
  });

  return (
    <GKModal
      open={isModalOpen}
      setOpen={setIsModalOpen}
      modalTitle={"Add New Corporate Action"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button type="submit" form="corporate-action-form" variant="outlined">
            Submit
          </Button>
        </Grid>
      }
    >
      <Formik
        initialValues={{
          act_type: "",
          act_date: "",
          per_share: "",
          for_every: "",
          new_share_number: "",
          bonus_amount: "",
          existing_asset_quantity: "",
          new_asset_quantity: "",
          parent_instrument: "",
          cost_acquisition_ratio: "",
          re_gcode: "",
          premium: "",
          purpose: "",
          closing_date: "",
          isCeil: false,
          demerged_companies: [
            {
              instrument: "",
              new_asset_quantity: "",
              cost_acquisition_ratio: "",
            },
          ],
        }}
        onSubmit={async (values) => {
          await axiosInstance
            .post(`/admin-instrument-ca/${id}/create`, values)
            .then((res) => {
              toast.success(res.data.message);
              refetch();
              setIsModalOpen(false);
            })
            .catch((error) => {
              toast.error(
                error?.response?.data?.message || "Something went wrong"
              );
            });
        }}
      >
        {({ values, handleChange, handleBlur, setFieldValue }) => {
          return (
            <Form id="corporate-action-form">
              <Grid container spacing={2}>
                <Grid item container xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isCeil"
                        checked={values.isCeil}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Ceil Fraction Holdings"
                  />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                      <GKSelect
                        name="act_type"
                        inputLabel="Action Type"
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.act_type}
                      >
                        <MenuItem style={{ display: "none" }} value="">
                          Select Code Type
                        </MenuItem>
                        {CORPORATE_ACTION_TYPE?.map((dataItem, i) => (
                          <MenuItem key={i} value={dataItem.value}>
                            {dataItem.name}
                          </MenuItem>
                        ))}
                      </GKSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        type="date"
                        name="act_date"
                        inputLabel="Action Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.act_date}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {values.act_type === "DIV" && (
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          type="number"
                          name="per_share"
                          inputLabel="Per Share"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.per_share}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {(values.act_type === "SPL" ||
                  values.act_type === "RIGHTS" ||
                  values.act_type === "RVS") && (
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          type="number"
                          name="for_every"
                          inputLabel="For Every"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.for_every}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          type="number"
                          name="new_share_number"
                          inputLabel="New Share Number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.new_share_number}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {values.act_type === "BNS" && (
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          type="number"
                          name="for_every"
                          inputLabel="For Every"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.for_every}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          type="number"
                          name="bonus_amount"
                          inputLabel="Bonus Amount"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.bonus_amount}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {values.act_type === "MRG" && (
                  <>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            type="number"
                            name="existing_asset_quantity"
                            inputLabel="Existing Asset Quantity"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.existing_asset_quantity}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            type="number"
                            name="new_asset_quantity"
                            inputLabel="New Asset Quantity"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.new_asset_quantity}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <GKSearchSelect
                          inputLabel="Parent Instrument"
                          disabled={isFetching}
                          options={perentInstrument || []}
                          getOptionLabel={(option) => option.name || option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select Company Name"
                            />
                          )}
                          fullWidth
                          onChange={(e, val) => {
                            setFieldValue("parent_instrument", val.value);
                          }}
                          value={
                            perentInstrument.filter(
                              (data: any) =>
                                data?.gcode === values.parent_instrument
                            )[0]?.name
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {values.act_type === "DMG" && (
                  <>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            type="number"
                            name="existing_asset_quantity"
                            inputLabel="Existing Asset Quantity"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.existing_asset_quantity}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            type="number"
                            name="new_asset_quantity"
                            inputLabel="New Asset Quantity"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.new_asset_quantity}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            type="number"
                            name="cost_acquisition_ratio"
                            inputLabel="Cost Acquisition Ratio"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cost_acquisition_ratio}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <FieldArray name="demerged_companies">
                      {({ remove, push }) => (
                        <>
                          <Grid
                            container
                            mt={2}
                            display={"flex"}
                            justifyContent={"flex-end"}
                          >
                            <Grid item>
                              <Button
                                onClick={() =>
                                  push({
                                    instrument: "",
                                    new_asset_quantity: "",
                                    cost_acquisition_ratio: "",
                                  })
                                }
                                size="large"
                                variant="outlined"
                              >
                                Add Stock
                              </Button>
                            </Grid>
                          </Grid>
                          {values.demerged_companies.map(
                            (dataItem: any, index: any) => {
                              return (
                                <>
                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <Grid item xs={6}>
                                      <Typography
                                        fontWeight={500}
                                        fontSize={18}
                                        // color={theme.palette.error.main}
                                      >
                                        Add Company
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      display={"flex"}
                                      justifyContent={"end"}
                                    >
                                      <IconButton
                                        disabled={
                                          values.demerged_companies?.length ===
                                          1
                                        }
                                        color="error"
                                        onClick={() => remove(index)}
                                      >
                                        <RiDeleteBinLine />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                  <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={6}>
                                      <GKSearchSelect
                                        inputLabel="Child Instrument"
                                        disabled={isFetching}
                                        options={perentInstrument || []}
                                        getOptionLabel={(option) =>
                                          option.name || option
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Company Name"
                                          />
                                        )}
                                        fullWidth
                                        onChange={(event, val) => {
                                          setFieldValue(
                                            `demerged_companies.${index}.instrument`,
                                            val.value
                                          );
                                        }}
                                        value={
                                          perentInstrument?.find(
                                            (data) =>
                                              data?.value ===
                                              values.demerged_companies[index]
                                                .instrument
                                          )?.name
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <FormControl variant="standard" fullWidth>
                                        <GKTextField
                                          type="number"
                                          name={`demerged_companies.${index}.new_asset_quantity`}
                                          inputLabel="New Asset Quantity Child"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.demerged_companies[index]
                                              .new_asset_quantity
                                          }
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    spacing={2}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <Grid item xs={6}>
                                      <FormControl variant="standard" fullWidth>
                                        <GKTextField
                                          type="number"
                                          name={`demerged_companies.${index}.cost_acquisition_ratio`}
                                          inputLabel="Cost Acquisition Ratio Child"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.demerged_companies[index]
                                              .cost_acquisition_ratio
                                          }
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                    </FieldArray>
                  </>
                )}
                {(values.act_type === "RIGHTS" ||
                  values.act_type === "REC") && (
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <GKSearchSelect
                        inputLabel={
                          values.act_type === "RIGHTS"
                            ? "Right Issue Instrument"
                            : "Partly Paid Instrument"
                        }
                        disabled={isFetching}
                        options={perentInstrument || []}
                        getOptionLabel={(option) => option.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select Company Name"
                          />
                        )}
                        fullWidth
                        onChange={(e, val) => {
                          setFieldValue("re_gcode", val.value);
                        }}
                        value={
                          perentInstrument.filter(
                            (data: any) => data?.gcode === values.re_gcode
                          )[0]?.name
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          type="number"
                          name="premium"
                          inputLabel="Premium"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.premium}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {values.act_type === "REC" && (
                  <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        type="date"
                        name="closing_date"
                        inputLabel="Closing Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.closing_date}
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <GKTextArea
                    minRows={5}
                    inputLabel="Purpose"
                    name="purpose"
                    value={values.purpose}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />{" "}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </GKModal>
  );
}

export default AddCorporateActionModal;
