import { Button, Grid, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { GKDataGrid, StatusField } from "components";
import dayjs from "dayjs";
import { useState } from "react";
import toast from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import AddCorporateActionModal from "./AddCorporateActionModal";

function CorporateActions() {
  const { id } = useParams();
  const [isOpenCorporateAction, setIsOpenCorporateACtion] = useState(undefined);

  const {
    state: { currentUser },
  } = useAppContext();

  const isDisabled = currentUser?.phone === "9911223344";

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["ADMIN_INSTRUMENT_CA"],
    queryFn: async () => axiosInstance.get(`/admin-instrument-ca/${id}/`),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
    refetchInterval: 60 * 1000,
  });

  const corporateColumns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 80, 1),
      valueGetter: (row) => dayjs(row?.row?.date).format("DD/MM/YYYY"),
    },
    {
      ...extraColumnsField("Action Type", "type", 80, 1),
    },
    {
      ...extraColumnsField("Details", "details", 350, 1),
      renderCell: (row) => {
        return (
          <>
            {row?.row?.type === "dividend" && (
              <Typography>
                <b>Dividend Per Share:</b> {row?.row?.dividendPerShare}
              </Typography>
            )}

            {(row?.row?.type === "split" ||
              row?.row?.type === "bonus" ||
              row?.row?.type === "reverse_split") && (
              <Grid container>
                <Grid xs={12}>
                  <Typography>
                    <b>For Stocks: </b>
                    {row?.row?.forStocks}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography>
                    <b>Issued Stocks:</b> {row?.row?.issuedStocks}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {row?.row?.type === "demerger" && (
              <Grid container>
                <Grid xs={12}>
                  <Typography>
                    <b>Existing Asset Quantity: </b>
                    {row?.row?.existingAssetQuantity}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography>
                    <b>New Asset Quantity:</b>
                    {row?.row?.newAssetQuantity}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography>
                    <b>Cost Acquisition Ratio:</b>
                    {row?.row?.costAcquisitionRatio}
                  </Typography>
                </Grid>
                {row?.row?.demergedCompanies?.map((company: any) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item container>
                    <Grid xs={12}>
                      <Typography>
                        <b>Demerged Company:</b>
                        {company.childCompany}
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography className="flex flex-row gap-2">
                        <b>New Asset Quantity:</b>
                        {company.newAssetQuantity}
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography className="flex flex-row gap-2">
                        <b>Cost Acquisition Ratio:</b>
                        {company.costAcquisitionRatio}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}

            {row?.row?.type === "merger" && (
              <Grid container>
                <Grid xs={12}>
                  <b>Existing Asset Quantity:</b>
                  {row?.row?.existingAssetQuantity}
                </Grid>
                <Grid xs={12}>
                  <b>New Asset Quantity:</b>
                  {row?.row?.newAssetQuantity}
                </Grid>
                <Grid xs={12}>
                  <b>Parent Company:</b>
                  {row?.row?.parentCompany}
                </Grid>
              </Grid>
            )}

            {row?.row?.type === "right_issue" && (
              <Grid container>
                <Grid xs={12}>
                  <Typography>
                    <b>For Stocks: </b>
                    {row?.row?.forStocks}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography>
                    <b>Issued Stocks:</b> {row?.row?.issuedStocks}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <b>premium:</b>
                  {row?.row?.premium}
                </Grid>
                <Grid xs={12}>
                  <b>Right Issue Instrument:</b>
                  {row?.row?.reGcode}
                </Grid>
              </Grid>
            )}

            {row?.row?.type === "REC" && (
              <Grid container>
                <Grid xs={12}>
                  <b>premium:</b>
                  {row?.row?.premium}
                </Grid>
                <Grid xs={12}>
                  <b>closingDate:</b>
                  {dayjs(row?.row?.closingDate).format("DD/MM/YYYY")}
                </Grid>
                <Grid xs={12}>
                  <b>Patly Paid Instrument:</b>
                  {row?.row?.reGcode}
                </Grid>
              </Grid>
            )}
          </>
        );
      },
    },
    {
      ...extraColumnsField("Purpose", "purpose", 150, 1),
      valueGetter: (params) => params?.row?.purpose || "-",
    },
    {
      ...extraColumnsField("Ceil", "isCeil", 150, 1),
      renderCell: (params) => (
        <StatusField statusString={params?.row?.isCeil ? "True" : "False"} />
      ),
    },
    {
      ...extraColumnsField("Action", "action", 50, 1),
      renderCell: (params) => {
        return (
          <IconButton
            disabled={isDisabled}
            onClick={async () => {
              await axiosInstance
                .delete(`/admin-instrument-ca/${id}/delete`, {
                  params: {
                    ca_id: params.row.caId,
                    act_type: params.row.type,
                  },
                })
                .then((res) => {
                  toast.success(res.data.message);
                  refetch();
                })
                .catch((error) => {
                  toast.error(
                    error?.response?.data?.message || "Something went wrong"
                  );
                });
            }}
          >
            <AiFillDelete size={20} />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} mb={2} display={"flex"} justifyContent={"end"}>
        <Button
          variant="contained"
          disabled={isDisabled}
          size="medium"
          onClick={() => setIsOpenCorporateACtion(!isOpenCorporateAction)}
        >
          Add Corporate Action
        </Button>
      </Grid>
      <Grid item xs={12}>
        <GKDataGrid
          loading={isFetching}
          columns={corporateColumns}
          rows={
            data?.data?.data?.map((data: any, index: number) => {
              return {
                ...data,
                id: index,
              };
            }) || []
          }
          rowHeight={150}
        />
      </Grid>

      {isOpenCorporateAction && (
        <AddCorporateActionModal
          isModalOpen={isOpenCorporateAction}
          setIsModalOpen={setIsOpenCorporateACtion}
          refetch={refetch}
        />
      )}
    </Grid>
  );
}

export default CorporateActions;
273;
