import { Button, Grid, IconButton } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { GKClientDataGrid } from "components";
import dayjs from "dayjs";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import GroupModal from "./GroupModal";

const Group = () => {
  const [groupModal, setGroupModal] = useState(undefined);
  const [groupData, setGroupData] = useState({});

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["GET_ALL_GROUP"],
    queryFn: () => axiosInstance.get("/advisor_group/"),
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Group Name", "title", 150, 1),
    },
    {
      ...extraColumnsField("Created At", "createdAt", 150, 1),
      valueFormatter: (params) => dayjs(params?.value).format("DD MMM YYYY"),
      valueGetter: (row) => row?.row?.createdAt || "N/A",
    },
    {
      ...extraColumnsField("Actions", "action", 80, 1),
      headerAlign: "right",
      align: "right",
      type: "action",
      renderCell: (item) => (
        <Grid container gap={1} justifyContent={"flex-end"}>
          <IconButton
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              setGroupData(item.row);
              setGroupModal(true);
            }}
          >
            <MdEdit size={20} />
          </IconButton>
        </Grid>
      ),
    },
  ];

  const GroupDetailTable = (params: GridRowParams) => {
    const { row } = params;

    const columns: GridColDef[] = [
      {
        ...extraColumnsField("Name", "name", 150, 1),
      },
      {
        ...extraColumnsField("Phone", "phone", 150, 1),
      },
      {
        ...extraColumnsField("Company Name", "companyName", 150, 1),
      },
    ];

    return (
      <Grid container px={2} py={1}>
        <GKClientDataGrid
          density="compact"
          showFiltersButton={false}
          columns={columns}
          rows={row.users || []}
          showHeaderGrid={false}
          pagination={false}
        />
      </Grid>
    );
  };

  return (
    <>
      <GKClientDataGrid
        headerComponent={
          <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              onClick={() => {
                setGroupData({});
                setGroupModal(!groupModal);
              }}
            >
              Create Group
            </Button>{" "}
          </Grid>
        }
        loading={isFetching}
        showFiltersButton={false}
        columns={columns}
        rows={data?.data?.data || []}
        getDetailPanelContent={GroupDetailTable}
      />

      {groupModal && (
        <GroupModal
          open={groupModal}
          setOpen={setGroupModal}
          refetch={refetch}
          groupData={groupData}
        />
      )}
    </>
  );
};

export default Group;
