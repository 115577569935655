/* eslint-disable react/no-danger */
import {
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Skeleton,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DashboardLayout from "layouts/DashboardLayout";
import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { IoIosNotifications } from "react-icons/io";
import { useParams } from "react-router-dom";
import { theme } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";

const AdvisorDetails = () => {
  const { id } = useParams();
  // const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: whatsappHistory } = useQuery({
    queryKey: ["WHATSAPP_HISTORY"],
    queryFn: () =>
      axiosInstance.get(`/super_admin_whatsapp/${id}/whatsapp_history/`),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { data, isFetching } = useQuery({
    queryKey: ["ADVISOR_DETAILS"],
    queryFn: () => axiosInstance.get(`/super_admin/${id}/advisor/`),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const advisorDetails = [
    {
      name: "Company name",
      value: data?.data?.companyName || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Advisor name",
      value: data?.data?.name || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Phone number",
      value: data?.data?.phone || (isFetching ? <Skeleton /> : 0),
    },
  ];

  const advisorsOtherInformation = [
    {
      name: "Total Aum",
      value: data?.data?.totalAum || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Branch",
      value: data?.data?.branches || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Employees",
      value: data?.data?.employees || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Clients",
      value: data?.data?.clients || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Families",
      value: data?.data?.families || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Portfolios",
      value: data?.data?.portfolios || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Groups",
      value: data?.data?.groups || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Transactions",
      value: data?.data?.transactions || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Cash entries",
      value: data?.data?.cashEntries || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Uploads",
      value: data?.data?.transactionUploads || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Contract Notes",
      value: data?.data?.contractNotes || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Report Generated",
      value: data?.data?.reports || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Leads",
      value: data?.data?.leads || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "New Clients Today",
      value: data?.data?.newClientToday || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Equity Basket Mapped Portfolios",
      value:
        data?.data?.modelPortfolioMappedPortfolios ||
        (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Equity Basket Orders",
      value:
        data?.data?.modelPortfolioOrders || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Equity Basket UniqueStocks",
      value:
        data?.data?.modelPortfolioUniqueStocks ||
        (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Equity Basket Versions",
      value:
        data?.data?.modelPortfolioVersions || (isFetching ? <Skeleton /> : 0),
    },
    {
      name: "Equity Basket",
      value: data?.data?.modelPortfolios || (isFetching ? <Skeleton /> : 0),
    },
  ];

  return (
    <DashboardLayout
      title="Advisor Details"
      breadcrumbs={[
        {
          name: "Dashboard",
          path: "/app/dashboard",
        },
        {
          name: "Advisor Details",
          path: "",
        },
      ]}
      toolbar={
        <>
          {whatsappHistory?.data?.data.length !== 0 && (
            <IconButton onClick={handleClick}>
              <IoIosNotifications />
            </IconButton>
          )}
          {/* <Button onClick={() => navigate(-1)}>
            Back &nbsp; <TbArrowBackUp />
          </Button> */}
        </>
      }
    >
      <Card>
        <Grid container spacing={2.5}>
          {advisorDetails.map((item) => (
            <Grid item xs={4}>
              <Card
                sx={{
                  padding: 2,
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  borderRadius: 2,
                  textAlign: "center",
                }}
                key={item.name}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={theme.palette.text.secondary}
                  marginTop={1}
                >
                  {item.name}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={700}
                  color={theme.palette.primary.main}
                >
                  {item?.value}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>
      <Card>
        <Grid container spacing={2.5}>
          {advisorsOtherInformation.map((item) => (
            <Grid item xs={4}>
              <Card
                sx={{
                  padding: 2,
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  borderRadius: 2,
                  textAlign: "center",
                }}
                key={item.name}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={theme.palette.text.secondary}
                >
                  {item.name}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={700}
                  color={theme.palette.primary.main}
                  marginTop={1}
                >
                  {item?.value}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {whatsappHistory?.data?.data.length === 0 && (
            <ListItem alignItems="flex-start">
              <ListItemText primary={"No Logs found"} />
            </ListItem>
          )}

          {whatsappHistory?.data?.data.map((dataItem: any) => {
            return (
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography
                      sx={{ display: "inline", fontWeight: 700 }}
                      component="span"
                      variant="body2"
                    >
                      {dataItem?.template?.templateTitle || "-"}
                    </Typography>
                  }
                  secondary={
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ display: "inline", fontWeight: 500 }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {dataItem?.template?.templateName || "-"}
                        </Typography>
                        {` — ${dataItem?.template?.templateContent}`}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={
                          dataItem?.template?.includeDocument
                            ? "space-between"
                            : "flex-end"
                        }
                        alignItems={"center"}
                      >
                        {dataItem?.template?.includeDocument && (
                          <Button
                            sx={{ textDecoration: "underline" }}
                            href={dataItem?.document}
                            target="_blank"
                          >
                            Document
                          </Button>
                        )}
                        <Typography
                          color={theme.palette.primary.main}
                          fontWeight={600}
                          fontSize={12}
                        >
                          {moment(dataItem?.createdAt).format(
                            "DD/MM/YYYY hh:mm:ss A"
                          ) || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
            );
          })}
          <Divider variant="inset" component="li" />
        </List>
      </Popover>
    </DashboardLayout>
  );
};

export default AdvisorDetails;
