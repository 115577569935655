import { Button, Chip, FormControl, Grid, MenuItem } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { DragDrop, GKModal, GKSelect, GKTextField, Heading } from "components";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  refetch: any;
  detailsData: any;
}

const BrokerMasterDetailsModal = (props: Props) => {
  const { id } = useParams();

  const { isModalOpen, setIsModalOpen, refetch, detailsData } = props;

  const { mutate: handleCreateBrokerDetails } = useMutation({
    mutationKey: ["CREATE_BROKEN_DETAILS"],
    mutationFn: (data: any) =>
      axiosInstance.post("/broker-integration/create/", data),
    onSuccess: (response) => {
      refetch();
      toast.success(
        response?.data?.message || "Create Broker Integration Successfully"
      );
      setIsModalOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleUpdateBrokerDetails } = useMutation({
    mutationKey: ["UPDATE_BROKEN_DETAILS"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/broker-integration/${detailsData.id}/update/`,
        data
      ),
    onSuccess: (response) => {
      refetch();
      toast.success(
        response?.data?.message || "Update Broker Integration Successfully"
      );
      setIsModalOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      fileType: detailsData?.id ? detailsData.fileType : "",
      dailyBulk: detailsData?.id ? detailsData.dailyBulk : "",
      template: [],
      version: detailsData?.id ? detailsData?.version : "",
    },
    onSubmit: async (values) => {
      const brokerData = new FormData();

      brokerData.append("file_type", values.fileType);
      brokerData.append("daily_bulk", values.dailyBulk);
      brokerData.append("version", values.version);
      brokerData.append("template", values.template[0]);
      brokerData.append("broker_id", id);

      if (detailsData?.id) {
        handleUpdateBrokerDetails(brokerData);
      } else {
        handleCreateBrokerDetails(brokerData);
      }
    },
  });

  return (
    <GKModal
      open={isModalOpen}
      setOpen={setIsModalOpen}
      modalTitle={
        detailsData?.id ? "Update Broker Details" : "Add New Broker Details"
      }
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button
            type="submit"
            form="create-broker-details-from"
            variant="outlined"
          >
            {detailsData?.id ? "Update" : "Submit"}
          </Button>
        </Grid>
      }
    >
      <form onSubmit={formik.handleSubmit} id="create-broker-details-from">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="FileType"
                onChange={formik?.handleChange}
                value={formik.values.fileType}
                name="fileType"
              >
                <MenuItem value={"csv"}>CSV</MenuItem>
                <MenuItem value={"pdf"}>PDF</MenuItem>
              </GKSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="Daily Bulk"
                onChange={formik?.handleChange}
                value={formik.values.dailyBulk}
                name="dailyBulk"
              >
                <MenuItem value={"daily"}>Daily</MenuItem>
                <MenuItem value={"bulk"}>Bulk</MenuItem>
              </GKSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Version"
                onChange={formik?.handleChange}
                value={formik.values.version}
                name="version"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Heading
              heading="Upload template"
              subHeading="Please upload the template in PDF and CSV format"
            />
            <DragDrop
              value={formik.values.template}
              onChange={(e) => formik.setFieldValue("template", e)}
              supportName={".PDF, .CSV"}
            />
          </Grid>

          {detailsData?.template && !formik.values.template.length && (
            <Grid item xs={12}>
              <Chip label={detailsData?.template} />
            </Grid>
          )}
        </Grid>
      </form>
    </GKModal>
  );
};

export default BrokerMasterDetailsModal;
