import { Button, FormControl, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { GKModal, GKTextField } from "components";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: any;
  walletHistoryRefetch: any;
}

const WalletTopupModal = (props: Props) => {
  const { id } = useParams();

  const { open, setOpen, refetch, walletHistoryRefetch } = props;

  const { mutate } = useMutation({
    mutationKey: ["UPDATE_WALLET_BALANCE"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/super_admin/${id}/wallet_balance/`, data),
    onSuccess: (response) => {
      refetch();
      walletHistoryRefetch();
      toast.success(response?.data?.message || "Broker Update Successfully");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    onSubmit: (values: any) => {
      mutate(values);
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Wallet Topup"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button type="submit" form="wallet-topup-form" variant="outlined">
            Update
          </Button>
        </Grid>
      }
    >
      <form onSubmit={formik.handleSubmit} id="wallet-topup-form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                type="number"
                inputLabel="amount"
                onChange={formik?.handleChange}
                value={formik.values.amount}
                name="amount"
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default WalletTopupModal;
