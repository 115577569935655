import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { CommonPagination, GKTable, StatusField } from "components";
import toast from "react-hot-toast";

import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

const ClientsTable = () => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.client?.take,
    skip: commonFilter?.client?.skip,
    sort: JSON.stringify(
      commonFilter?.client?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.client?.search || "",
  };

  const { data, isFetching } = useQuery({
    queryKey: ["DASHBOARD_CLIENT_TABLE", requestBody],
    queryFn: () =>
      axiosInstance.get(`/super_admin/clients/`, {
        params: requestBody,
      }),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Name", "name", 150, 1),
      valueGetter: (row) => row?.row?.name || "-",
    },
    {
      ...extraColumnsField("Phone Number", "phone", 150, 1),
      type: "number",
      valueGetter: (row) => row?.row?.phone || "-",
    },
    {
      ...extraColumnsField("Company Name", "companyName", 150, 1),
      valueGetter: (row) => row?.row?.companyName || "-",
    },
    {
      ...extraColumnsField("Advisor", "advisor", 150, 1),
      valueGetter: (row) => row?.row?.advisor || "-",
    },
    // {
    //   headerName: 'Agreement Status',
    //   field: 'agreementStatus',
    //   disableColumnMenu: true,
    //   flex: 1,
    //   minWidth: 150,
    //   valueGetter: (row) => row?.row?.agreementStatus || '-',
    //   renderCell: (props) => {
    //     return <StatusField statusString={`${props?.row?.agreementStatus}`} />
    //   },
    // },
    {
      ...extraColumnsField("Risk Score", "riskScore", 150, 1),
      valueGetter: (row) => row?.row?.riskScore || "-",
      renderCell: (props) => {
        return <StatusField statusString={`${props?.row?.riskScore}`} />;
      },
    },
    {
      ...extraColumnsField("KYC Status", "kycStatus", 150, 1),
      valueGetter: (row) => row?.row?.kycStatus || "-",
      renderCell: (props) => {
        return <StatusField statusString={`${props?.row?.kycStatus}`} />;
      },
    },
    {
      ...extraColumnsField("Created on", "createdOn", 150, 1),
      // valueFormatter: (params) => dayjs(params?.value).format("DD MMM YYYY"),
      valueGetter: (row) => row?.row?.createdOn || "N/A",
    },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination name="client" totalCount={data?.data.totalCount || 0} />
    );
  };

  return (
    <GKTable
      loading={isFetching}
      columns={columns}
      name="client"
      rows={data?.data?.data || []}
      CustomPagination={!isFetching && cusPagination}
    />
  );
};

export default ClientsTable;
