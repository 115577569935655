import { Button, Card, FormControl, Grid, MenuItem } from "@mui/material";
import { useAppContext } from "AppContext";
import { GKSelect, GKTextField, Heading } from "components";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import {
  COMPANY_CAP,
  COMPANY_ETF_SUB_TYPE,
  COMPANY_INSTTYPE,
  COMPANY_STATUS,
  INSTRUMENT_SUB_TYPE,
  MUTUAL_FUND_SUB_TYPE,
} from "utils/constants";

function FundamentalDetails({ companyDetails, refetch }: any) {
  const { id } = useParams();

  const {
    state: { currentUser },
  } = useAppContext();

  const isDisabled = currentUser?.phone === "9911223344";

  const formik = useFormik({
    initialValues: {
      cap: companyDetails?.cap || "",
      finCode: companyDetails?.finCode || "",
      house: companyDetails?.house || "",
      industry: companyDetails?.industry || "",
      instType: companyDetails?.instType || "",
      kiteCode: companyDetails?.kiteCode || "",
      name: companyDetails?.name || "",
      sector: companyDetails?.sector || "",
      shortName: companyDetails?.shortName || "",
      status: companyDetails?.status || "",
      etfSubType: companyDetails?.etfSubType || "NA",
      mfSubType: companyDetails?.mfSubType || "",
      instSubType: companyDetails?.instSubType || "",
    },
    onSubmit: async (values) => {
      await axiosInstance
        .put(`/admin-instrument/${id}/update/`, values)
        .then((res) => {
          toast.success(res.data.message);
          refetch();
        })
        .catch((error) =>
          toast.error(error?.response?.data?.message || "Something went wrong")
        );
    },
  });

  return (
    <Card>
      <Heading
        border
        heading="Fundamental Details"
        subHeading="please enter the following details"
      />
      <form onSubmit={formik.handleSubmit} id="fundamental-details">
        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="name"
                onChange={formik?.handleChange}
                value={formik.values.name}
                name="name"
                disabled={isDisabled}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="ShortName"
                onChange={formik?.handleChange}
                value={formik.values.shortName}
                name="shortName"
                disabled={isDisabled}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="FinCode"
                onChange={formik?.handleChange}
                value={formik.values.finCode}
                name="finCode"
                disabled={isDisabled}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="InstType"
                onChange={formik?.handleChange}
                value={formik.values.instType}
                name="instType"
                disabled={isDisabled}
              >
                {COMPANY_INSTTYPE.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="Cap"
                onChange={formik?.handleChange}
                value={formik.values.cap}
                name="cap"
                disabled={isDisabled}
              >
                {COMPANY_CAP.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            </FormControl>
            {formik.values.instType === "ETF" && (
              <FormControl variant="standard" fullWidth>
                <GKSelect
                  inputLabel="EtfSubType"
                  onChange={formik?.handleChange}
                  value={formik.values.etfSubType}
                  name="etfSubType"
                  disabled={isDisabled}
                >
                  {COMPANY_ETF_SUB_TYPE.map((dataItem, i) => (
                    <MenuItem key={i} value={dataItem.value}>
                      {dataItem.name}
                    </MenuItem>
                  ))}
                </GKSelect>
              </FormControl>
            )}
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="Mutual Funds Sub Type"
                onChange={formik?.handleChange}
                value={formik.values.mfSubType}
                name="mfSubType"
                disabled={isDisabled}
              >
                {MUTUAL_FUND_SUB_TYPE.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Sector"
                onChange={formik?.handleChange}
                value={formik.values.sector}
                name="sector"
                disabled={isDisabled}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Industry"
                onChange={formik?.handleChange}
                value={formik.values.industry}
                name="industry"
                disabled={isDisabled}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="House"
                onChange={formik?.handleChange}
                value={formik.values.house}
                name="house"
                disabled={isDisabled}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="KiteCode"
                onChange={formik?.handleChange}
                value={formik.values.kiteCode}
                name="kiteCode"
                disabled={isDisabled}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="Status"
                onChange={formik?.handleChange}
                value={formik.values.status}
                name="status"
                disabled={isDisabled}
              >
                {COMPANY_STATUS.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="Instrument Sub Type"
                onChange={formik?.handleChange}
                value={formik.values.instSubType}
                name="instSubType"
                disabled={isDisabled}
              >
                {INSTRUMENT_SUB_TYPE.map((dataItem, i) => (
                  <MenuItem key={i} value={dataItem.value}>
                    {dataItem.name}
                  </MenuItem>
                ))}
              </GKSelect>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mt={2} display={"flex"} justifyContent={"flex-end"}>
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              type="submit"
              disabled={isDisabled}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}

export default FundamentalDetails;
