import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DragDrop,
  GKModal,
  GKReactQuill,
  GKSearchSelect,
  GKTextField,
  Heading,
} from "components";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { theme } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";

interface Props {
  inCardOpen: boolean;
  setIsCardOpen: (value: boolean) => void;
  refetch: any;
  cardData: any;
}

const CreateCard = (props: Props) => {
  const { inCardOpen, setIsCardOpen, refetch, cardData } = props;

  const [listOfFaqModule, setListOfFaqModule] = useState([]);

  const { isFetching } = useQuery({
    queryKey: ["GET-FAQ-MODULE"],
    queryFn: () => axiosInstance.get("/faq-modules/"),
    onSuccess: (response) => {
      setListOfFaqModule(
        response.data.data.map((dataItem: any) => {
          return {
            name: dataItem.moduleName,
            value: dataItem.id,
          };
        })
      );
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleCreateFaqDetails } = useMutation({
    mutationKey: ["CREATE_FAQ_DETAILS"],
    mutationFn: (data: any) => axiosInstance.post("/faq/", data),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Create FAQ Successfully");
      setIsCardOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleUpdateFaqDetails } = useMutation({
    mutationKey: ["UPDATE_FAQ_DETAILS"],
    mutationFn: (data: any) => axiosInstance.put(`/faq/${cardData.id}/`, data),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Update FAQs Successfully");
      setIsCardOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      question: cardData?.question || "",
      description: cardData?.description || "",
      media_file: cardData?.mediaFiles || [],
      faq_module_id: cardData?.moduleName || [],
    },
    onSubmit: async (values) => {
      const faqData: any = new FormData();

      faqData.append("question", values.question);
      faqData.append("description", values?.description);
      faqData.append(
        "faq_module_id",
        JSON.stringify(values?.faq_module_id?.map((data: any) => +data))
      );

      if (values.media_file) {
        faqData.append("media_file", values.media_file[0]);
      }

      if (cardData?.id) {
        handleUpdateFaqDetails(faqData);
      } else {
        handleCreateFaqDetails(faqData);
      }
    },
  });

  return (
    <GKModal
      open={inCardOpen}
      setOpen={setIsCardOpen}
      modalTitle={cardData?.id ? "Update FAQ Details" : "Add New FAQ Details"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button variant="outlined" onClick={() => setIsCardOpen(false)}>
            Close
          </Button>
          <Button type="submit" form="create-FAQ-from" variant="contained">
            {cardData?.id ? "Update" : "Submit"}
          </Button>
        </Grid>
      }
    >
      <form onSubmit={formik.handleSubmit} id="create-FAQ-from">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GKSearchSelect
              inputLabel="FAQ modules"
              multiple
              disabled={isFetching || cardData?.id}
              options={listOfFaqModule || []}
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select"
                />
              )}
              fullWidth
              onChange={(e, val) => {
                const selectedIds = val.map((option: any) => option.value);
                formik.setFieldValue("faq_module_id", selectedIds);
              }}
              value={
                listOfFaqModule?.filter((option: any) =>
                  formik?.values?.faq_module_id?.includes(option.value)
                ) || []
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="question"
                onChange={formik?.handleChange}
                value={formik.values.question}
                name="question"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <GKReactQuill
              value={formik.values.description}
              handleChange={(values: any) =>
                formik.setFieldValue("description", values)
              }
              inputLabel="Description"
            />
          </Grid>
          {formik.values?.media_file[0]?.mediaFile ? (
            <Grid item xs={12}>
              {formik.values?.media_file?.map(
                (dataItem: {
                  mediaFile: string;
                  mediaFileName: string;
                  mediaFileType: string;
                }) => {
                  return (
                    <Grid
                      container
                      border={"solid 1px "}
                      borderColor={theme.palette.border.main}
                      borderRadius={2}
                      p={1}
                      my={1}
                      key={dataItem.mediaFileName}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontWeight={600}
                        fontSize={12}
                        width={"30%"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                      >
                        {dataItem.mediaFileName}
                      </Typography>

                      <IconButton
                        color="error"
                        onClick={() => {
                          formik.setFieldValue("media_file", []);
                        }}
                      >
                        <MdDelete />
                      </IconButton>
                    </Grid>
                  );
                }
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Heading
                heading="Upload File"
                subHeading="Please upload the file in PDF and CSV format"
              />
              <DragDrop
                value={formik.values.media_file}
                supportName={".PDF, .CSV"}
                onChange={(e) => formik.setFieldValue("media_file", e)}
              />
            </Grid>
          )}
        </Grid>
      </form>
    </GKModal>
  );
};

export default CreateCard;
