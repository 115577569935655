import { Button, FormControl, Grid, MenuItem } from "@mui/material";
import { useAppContext } from "AppContext";
import { GKModal, GKSelect, GKTextField } from "components";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { IDENTIFIER, IDENTIFIER_STATUS } from "utils/constants";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  refetch?: any;
  identifierRowData?: any;
}

function AddIdenifier(props: Props) {
  const { isModalOpen, setIsModalOpen, refetch, identifierRowData }: any =
    props;
  const { id } = useParams();

  const {
    state: { currentUser },
  } = useAppContext();

  const isDisabled = currentUser?.phone === "9911223344";

  const formik = useFormik({
    initialValues: {
      code_type: identifierRowData?.nbiId ? identifierRowData.codeType : "",
      code: identifierRowData?.nbiId ? identifierRowData.code : "",
      status: identifierRowData?.nbiId ? identifierRowData.status : "",
      series: identifierRowData?.nbiId ? identifierRowData.series : "",
    },
    onSubmit: async (values) => {
      const identifierData = new FormData();
      identifierData.append("code_type", values.code_type);
      identifierData.append("code", values.code);
      identifierData.append("status", values.status);
      identifierData.append("series", values.series);
      if (identifierRowData?.nbiId) {
        await axiosInstance
          .put(
            `/admin-instrument-meta/${identifierRowData?.nbiId}/update/`,
            identifierData
          )
          .then((res) => {
            toast.success(res.data.message);
            refetch();
            setIsModalOpen(false);
          })
          .catch((error) =>
            toast.error(
              error?.response?.data?.message || "Something went wrong"
            )
          );
      } else {
        await axiosInstance
          .post(`/admin-instrument-meta/${id}/create`, identifierData)
          .then((res) => {
            toast.success(res.data.message);
            refetch();
            setIsModalOpen(false);
          })
          .catch((error) => {
            toast.error(
              error?.response?.data?.message || "Something went wrong"
            );
          });
      }
    },
  });

  return (
    <GKModal
      open={isModalOpen}
      setOpen={setIsModalOpen}
      modalTitle={
        identifierRowData?.nbiId ? "Update Identifier" : "Add New Identifier"
      }
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button
            type="submit"
            form="identifier-form"
            variant="outlined"
            disabled={isDisabled}
          >
            {identifierRowData?.nbiId ? "Update" : "Submit"}
          </Button>
        </Grid>
      }
    >
      <form id="identifier-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <GKSelect
                  className="col-span-6"
                  name="code_type"
                  inputLabel="Code Type"
                  displayEmpty
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code_type}
                  disabled={identifierRowData?.nbiId}
                >
                  <MenuItem style={{ display: "none" }} value="">
                    Select Code Type
                  </MenuItem>
                  {IDENTIFIER?.map((dataItem, i) => (
                    <MenuItem key={i} value={dataItem.value}>
                      {dataItem.name}
                    </MenuItem>
                  ))}
                </GKSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <GKTextField
                  className="col-span-6"
                  name="code"
                  inputLabel="Code"
                  disabled={isDisabled}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                  inputProps={{
                    maxLength: 24,
                    pattern:
                      formik.values.code_type === "NSE"
                        ? "^[A-Za-z0-9]+$"
                        : "^[0-9]+$",
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <GKSelect
                  className="col-span-6"
                  name="status"
                  inputLabel="Status"
                  disabled={isDisabled}
                  displayEmpty
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.status}
                >
                  <MenuItem style={{ display: "none" }} value="">
                    Select Status
                  </MenuItem>
                  {IDENTIFIER_STATUS?.map((dataItem, i) => (
                    <MenuItem key={i} value={dataItem.value}>
                      {dataItem.name}
                    </MenuItem>
                  ))}
                </GKSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {formik.values.code_type === "NSE" && (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    className="col-span-6"
                    name="series"
                    inputLabel="Series"
                    disabled={isDisabled}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.series}
                    inputProps={{ maxLength: 6, pattern: "^[A-Za-z0-9]+$" }}
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
}

export default AddIdenifier;
